









































import {ToolMessage} from "@/request/store/ToolMessage";
import {StoreInfo} from "@/request/merchant/StoreInfo";

import {Vue, Component} from "vue-property-decorator";

@Component({
    name: 'store_tool_message'
})
export default class store_tool_message extends Vue {
    modal: any = ToolMessage;
    search: Array<any> = [
        {
            type: 'DatePicker',
            pickerType: 'datetimerange',
            format: 'yyyy-MM-dd HH:mm',
            label: '发送时间',
            width: '220px',
            placeholder: '请选择发送时间'
        }
    ];
    storeList: any = [];
    formData: any = {
        title: '',
        receiveId: '',
        content: ''
    };
    formRules: any = {
        title: {required: true, message: '请填写标题', trigger: 'blur'},
        content: {required: true, message: '请填写内容', trigger: 'blur'},
    };
    columns: Array<any> = [
        {
            title: '标题',
            key: 'title',
            minWidth: 120
        },
        {
            title: '消息类型',
            key: 'typeStr',
            minWidth: 120
        },
        {
            title: '接收人',
            key: 'receiveName',
            minWidth: 120
        },
        {
            title: '读取状态',
            key: 'bread',
            minWidth: 120,
            render: (h: any, p: any) => h('p', p.row.bread?'已读':'未读')
        },
        {
            title: '内容',
            key: 'content',
            minWidth: 200,
            tooltip: true
        },
        {
            title: '创建时间',
            key: 'createTime',
            minWidth: 180
        }
    ];
    created() {
        StoreInfo.getAll().then((body: any) => {
            this.storeList = body.data;
            this.storeList.unshift({id: '', name: '全部'});
        })
    }
    createNew() {
        delete this.formData.id;
    }
    edit(row: any) {
        this.formData = row;
    }
    submit() {
        let self: any = this;
        let params = {...self.formData};
        self.$refs['el-form'].validate((valid: boolean) => {
            if (valid) {
                self.$refs['el-table'].doSubmit(params).then(() => {

                });
            }
        })
    }
}
